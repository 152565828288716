import { Hypertext } from "@quortex/front-common"
import { captureException } from "@sentry/core"
import { FC, useEffect, useState } from "react"
import Divider from "src/components/base/misc/Divider"
import { ApiError } from "src/services/api"

interface Props {
  error: any
}

const ApiErrorToast: FC<Props> = ({ error }) => {
  const [isShowDetails, setIsShowDetails] = useState(false)

  useEffect(() => {
    if (!(error instanceof ApiError)) captureException(error)
  }, [error])

  return (
    <>
      <p className="font-bold">
        {error instanceof ApiError
          ? error.message === "Failed to fetch"
            ? "API connection lost"
            : "API Error"
          : "Error"}
      </p>
      <p className="text-sm">
        {error.message === "Failed to fetch"
          ? "We cannot reach the API… Make sure your network connection is active."
          : "Oops, something got quite wrong"}
      </p>
      <p>
        <Hypertext
          as="button"
          className="mt-2 underline !shadow-none"
          size="small"
          onClick={() => {
            setIsShowDetails(!isShowDetails)
          }}
        >
          {isShowDetails ? "hide details" : "show details"}
        </Hypertext>
      </p>
      {isShowDetails && error instanceof ApiError && (
        <>
          <Divider className="mx-0 my-2 h-px" />
          <span className="mt-2 text-sm">
            <p className="break-all">
              {error.method} {error.uri}
            </p>
            <p>HTTP {error.status}</p>
            <pre>{error.jsonData && JSON.stringify(error.jsonData, null, 2)}</pre>
          </span>
        </>
      )}
      {isShowDetails && !(error instanceof ApiError) && (
        <>
          <Divider className="mx-0 my-2 h-px" />
          <span className="mt-2 text-sm">{error.message}</span>
        </>
      )}
    </>
  )
}

export default ApiErrorToast
